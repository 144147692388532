import React from "react";
import dataProvider from "../../../../data/dataProvider";
import { is_empty } from "../../../../utils/validations";
import style from "../../../../Style.module.css";
import RouteLeavingPrompt from "../../../widgets/routeLeavingPrompt";
import { replace_all } from "../../../../utils/Utils";
import { useSelector, useDispatch } from "react-redux";
import constant from "../Customer/Constants/constant";

import api from "data/APIs";
import { useNotifications } from "utils/hooks";
import { Divider } from "@material-ui/core";
import {
  EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS,
  EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS,
} from "../redux/actions";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import ExlyLoader from "ui/modules/ExlyLoader";

const sourceLocation = window.location;

const Message = (props) => {
  const dispatch = useDispatch();
  const is_desktop = useDesktopMediaQuery();

  const [msg_title] = React.useState(
    !is_empty(props.communicationTitle) ? props.communicationTitle : null
  );
  const [type] = React.useState(!is_empty(props.type) ? props.type : null);

  const [user_type] = React.useState(
    constant.user_types_value[props.user_type] || null
  );

  // const [text_area_height, set_text_area_height] = React.useState(false);
  const [edited, set_edited] = React.useState(false);

  const categories = useSelector((state) =>
    user_type === constant.user_type_enum
      ? state.communications.user_categories
      : state.communications.creator_categories
  );

  const category = props.category;
  const selected_comm = !is_empty(categories)
    ? categories[category]?.list?.find((item) => item.title === msg_title)
    : null;

  const show_test_cta = sessionStorage.getItem("is_creator_testable") == "true";

  const comm_data = !is_empty(selected_comm)
    ? selected_comm.message_details
    : null;

  var text_array = !is_empty(selected_comm) ? selected_comm.text_array : null;

  const { pushNotification } = useNotifications();

  React.useEffect(() => {
    //User is in view mode when the modal opens and the save value is 0 inititally.
    //When User clicks on edit -> save increments -> and click on save again it increments
    //Calls the function only if the user is in edit mode currently.
    if (
      props.view === constant.edit_mode &&
      //Checks if the user is in Edit mode (2) only
      props.save === constant.view_type_enums.edit
    ) {
      handleSave();
    }
  }, [props.save]);

  React.useEffect(() => {
    if (show_test_cta && props.sendMessage > 0) {
      handleTestMsg();
    }
  }, [props.sendMessage]);

  React.useEffect(() => {
    const content_type = type;
    const userType =
      user_type === constant.user_type_enum
        ? constant.user_type
        : constant.creator_type;
    dispatch({
      type:
        user_type === constant.user_type_enum
          ? EXLY_COMMUNICATIONS_REQUEST_USER_MESSAGE_DETAILS
          : EXLY_COMMUNICATIONS_REQUEST_CREATOR_MESSAGE_DETAILS,
      params: { msg_title, content_type, userType },
      key: selected_comm?.category_type,
    });
  }, []);

  //Commenting this code out, remove this if you see this commented code after 30th Nov,2022

  React.useEffect(() => {
    if (props.view === constant.edit_mode) {
      for (let i = 0; i < text_array.length; i++) {
        if (!is_empty(text_array[i])) {
          resizeTextarea(i);
        }
      }
    }
  }, [props.view]);

  const resizeTextarea = (index) => {
    const textarea = document.getElementById(`auto-size-textarea${index}`);
    if (textarea) {
      textarea.style.height = "auto"; // Reset height before recalculating (removing this creates flickering issue during resizing so never remove this)
      textarea.style.height = `${textarea.scrollHeight}px`; // Set new height
    }
  };

  const handleSave = async (redirect = true) => {
    try {
      set_edited(false);
      let data = replace_all(
        text_array.join("$$"),
        "\n",
        type === constant.email ? "<br>" : "\n"
      );
      let payload = comm_data;
      delete payload.is_customer_login_enabled;
      payload[type === constant.email ? constant.email_body : constant.sms] =
        data;
      const status = await dataProvider.custom_request(
        `notifications/exly/${msg_title}/update`,
        "POST",
        payload
      );
      if (redirect)
        pushNotification(status.message, {
          variant: "outlined",
          color: "fine_pine",
        });
      if (status.status === 200) {
        //Initializing The View and The Save Mode
        props.setView(constant.view);
        props.setSave(constant.view_type_enums.initial);

        if (!redirect) {
          return true;
        }
        //Might Need it Later
        // else {
        // window.location.replace(
        //   `${window.location.origin}/#/CommunicationMessage/${category}/${user_type}/${msg_title}`
        // );
        // window.history.go(-1);
        // window.location.reload();
        // }
      }
    } catch (err) {
      console.log(err);
      if (!is_empty(err.body) && !is_empty(err.body.message)) {
        pushNotification(err.body.message, {
          variant: "outlined",
          color: "coral_red",
        });
      }
      return false;
    }
  };

  const renderInputs = () => {
    let return_array = [];
    for (let i = 0; i < text_array.length; i++) {
      if (i % 2 !== 0) {
        if (!is_empty(text_array[i])) {
          return_array.push(
            <div
              className={style.textDesign}
              dangerouslySetInnerHTML={{ __html: text_array[i] }}
            ></div>
          );
        }
      } else {
        if (!is_empty(text_array[i])) {
          return_array.push(
            <textarea
              id={`auto-size-textarea${i}`}
              onChange={(e) => {
                let value = e.target.value;
                let temp = text_array;
                temp[i] = value;
                text_array = temp;
                set_edited(true);
                resizeTextarea(i);
              }}
              className={`${style.comm_input} ${style.textarea_custom} ${
                !comm_data.is_customer_login_enabled &&
                text_array[i].includes("is_customer_login_enabled")
                  ? "d-none"
                  : ""
              }`}
            >
              {replace_all(text_array[i], "<br>", "\n")}
            </textarea>
          );
        }
      }
    }

    return return_array;
  };

  const handleTestMsg = async () => {
    try {
      const save_status = await handleSave(false);
      if (save_status) {
        const status = await dataProvider.custom_request(
          api.send_automated_test_msg,
          "POST",
          { comm_name: msg_title }
        );
        if (!is_empty(status?.message)) {
          pushNotification(status.message, {
            variant: "outlined",
            color: "fine_pine",
          });
        } else {
          pushNotification(constant.error_message, {
            variant: "outlined",
            color: "coral_red",
          });
        }
      }
    } catch (err) {
      console.log("handleTestMsg", err);
      if (err?.body?.message) {
        pushNotification(err?.body?.message, {
          variant: "outlined",
          color: "coral_red",
        });
      }
    }
  };

  if (is_empty(comm_data) || is_empty(text_array)) return <ExlyLoader />;

  return (
    <>
      <div
        className={`container  dm_font bottom_margin ${style.form_conatiner} `}
      >
        <div className={`row top_margin ${style.margin_bottom_10}`}>
          {!is_empty(selected_comm) && (
            <div>
              <div className={`${is_desktop ? "fit_content" : ""}`}>
                <div className={`${style.left_padding_15}`}>
                  <b>Message:</b>
                  <div className={`${style.labelText}`}>
                    {selected_comm.cdb_type}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Divider />
        <div className={`row ${style.top_margin_10} ${style.margin_bottom_10}`}>
          {!is_empty(selected_comm) && (
            <div>
              <div className={`${is_desktop ? "fit_content" : ""}`}>
                <div className={`${style.left_padding_15}`}>
                  <b>Sent On:</b>

                  <div className={`${style.labelText}`}>
                    {selected_comm.trigger}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Divider />

        <div className={`row ${style.top_margin_10} ${style.bottom_margin_50}`}>
          <div className={`col-12 `}>
            <div className={`${style.font_size_14} ${style.margin_bottom_5}`}>
              <b>Email:</b>
            </div>
            <div
              className={
                props.view === constant.edit_mode
                  ? style.editModeTextArea
                  : style.msg_container
              }
            >
              {props.view === constant.edit_mode ? (
                renderInputs()
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: replace_all(
                      comm_data.email_body.replaceAll(
                        "is_customer_login_enabled",
                        !comm_data.is_customer_login_enabled ? "d-none" : ""
                      ),
                      "$$",
                      ""
                    ),
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <Divider />

        {!is_empty(comm_data.sms) && props.view !== constant.edit_mode && (
          <div className={`row top_margin ${style.bottom_margin_50}`}>
            <div className={`col-12`}>
              <div className={style.font_size_14}>
                <b>Sms:</b>
                <div className={style.hint}>
                  **Due to new regulations of TRAI, SMS messages cannot be
                  edited currently
                </div>
              </div>

              <div className={style.msg_container}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: replace_all(
                      replace_all(comm_data.sms, "$$", ""),
                      "\n",
                      "<br>"
                    ),
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}

        <div className={`col-12 top_margin`}>
          <div className={style.grey_small_text}>
            (You can edit the text in black. The text in grey is not editable)
          </div>
        </div>
      </div>

      <RouteLeavingPrompt
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when={() => {
          return edited;
        }}
        primaryAction={() => {
          window.document.querySelector("#saveBtn").click();
        }}
        secondaryAction={() => {
          set_edited(false);
        }}
        sourceUrl={sourceLocation}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          if (!edited) {
            window.location.href = `${window.location.origin}/#${location.pathname}`;
            window.location.reload();
          }
          return edited;
        }}
      />
    </>
  );
};

export default Message;
